const state = {
  shop: null,
  items: [],
  deliveryFee: null,
};

const getters = {
  checkout: (state) => {
    const itemIds = state.items.map((i) => i.id);

    const totalAmount = state.items.reduce((total, item) => {
      return total + item.qty * item.price;
    }, 0);

    return {
      shop: state.shop,
      itemIds,
      items: state.items,
      totalAmount,
    };
  },
};

const actions = {
  setCheckout({ commit }, payload) {
    const { shop, items } = payload;
    commit('setCheckoutShop', shop);
    commit('setCheckoutItems', items);
  },
  clearCheckout({ commit }) {
    commit('unsetCheckoutShop');
    commit('unsetCheckoutItems');
  },
};

const mutations = {
  setCheckoutShop(state, shop) {
    state.shop = shop;
  },
  unsetCheckoutShop(state) {
    state.shop = null;
  },
  setCheckoutItems(state, items) {
    state.items = items;
  },
  unsetCheckoutItems(state) {
    state.items = [];
  },
};

export const checkout = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
