import cartService from '../services/cart.service';

// methods - temp cart
const cs_getCartList = async () => {
  try {
    let res = await cartService.getCartList();

    return {
      cartList:
        (res.data &&
          res.data.map((item) => ({
            tempId: item.id,
            ...item.product,
            qty: item.qty,
            remark: '',
          }))) ||
        [],
    };
  } catch (error) {
    console.error(error);
  }
};

const cs_addToCart = async (rootState, item) => {
  let itemList = [];
  const { id, qty, remark } = item;

  itemList.push({
    productId: id,
    qty: qty || 1,
    remark,
    registerId: rootState.auth.user.registerDTO.id,
  });

  try {
    let res = await cartService.registerToCart(itemList);

    return res.data && res.data.length > 0 && res.data[0]
      ? res.data[0].id
      : null;
  } catch (error) {
    console.error(error);
  }
};

const cs_removeFromCart = async (tempId) => {
  try {
    await cartService.removeFromCart([tempId]);
  } catch (error) {
    console.error(error);
  }
};

const cs_clearCart = async () => {
  try {
    await cartService.checkout();
  } catch (error) {
    console.error(error);
  }
};

// calcuate item price by conditons
const cs_getItemWithPrice = (item, isLoggedIn) => {
  let isBaseUnit = true;
  let appliedUnit = { name: 'Basic', id: null };

  if (item.productUnits && item.productUnits.length > 0) {
    // check multi unit
    let pUnitArrLen = item.productUnits.length;
    let pUnitItem = null;

    for (let index = 0; index < pUnitArrLen; index++) {
      pUnitItem = null;

      // check last or between, and get unit
      if (
        (index == pUnitArrLen - 1 &&
          item.qty >= item.productUnits[index].factor) ||
        (item.qty >= item.productUnits[index].factor &&
          item.qty < item.productUnits[index + 1].factor)
      )
        pUnitItem = item.productUnits[index];

      // set price
      if (pUnitItem) {
        if (item.isSpecialOffer) item.price = pUnitItem.unitSpecialPrice;
        else if (item.isPromo) item.price = pUnitItem.unitPromoPrice;
        else item.price = pUnitItem.unitPrice;

        isBaseUnit = false; // to announce to use other unit's price
        appliedUnit.id = pUnitItem.id;
        appliedUnit.name = pUnitItem.factorUnitName;
        break;
      }
    }
  }

  if (isBaseUnit) {
    if (item.isPromo && item.isSpecialOffer && item.specialOfferPrice)
      item.price = item.specialOfferPrice;
    else if (item.isSpecialOffer && item.specialOfferPrice)
      item.price = item.specialOfferPrice;
    else if (item.isPromo && item.promoPrice) item.price = item.promoPrice;
    else if (isLoggedIn) item.price = item.minPrice;
    else item.price = item.maxPrice;
  }

  return {
    ...item,
    amount: item.price * item.qty,
    appliedUnit,
  };
};
// end - methods - temp cart

const qtyRegx = /^[0-9]{1,7}$/;

const state = {
  cartList: [],
};

const getters = {
  itemCount: (state) => state.cartList.length,
  cartListByShopGroup: (state, getters, rootState) => {
    return state.cartList.reduce((results, item) => {
      //
      item = cs_getItemWithPrice(item, rootState.auth.status.loggedIn);

      let sellerIndex = results.findIndex((i) => i.shop.id == item.registerId);

      if (sellerIndex < 0) {
        // create seller
        results.push({
          shop: item.register,
          cartItems: [item],
          totalAmount: item.amount,
        });
      } else {
        // add seller cart item
        results[sellerIndex].cartItems.push(item);
        results[sellerIndex].totalAmount += item.amount;
      }

      return results;
    }, []);
  },
};

const actions = {
  async getCartList({ commit }) {
    try {
      let data = await cs_getCartList();
      commit('setCart', data.cartList || []);
    } catch (error) {
      console.error(error);
    }
  },
  async addToCart({ commit, state, rootState }, product) {
    const index = state.cartList.findIndex((item) => item.id === product.id);
    if (index < 0) {
      // new
      let tempId = await cs_addToCart(rootState, product); // register as temp
      commit('addItemToCart', { ...product, tempId });
    } else {
      // existed
      commit('increaseCartItemQtyByQty', { index, qty: product.qty });
    }
  },
  removeFromCart({ commit, state }, { id, tempId }) {
    const index = state.cartList.findIndex((cartItem) => cartItem.id === id);

    if (index > -1) {
      commit('removeItemFromCart', index);

      // remove from temp
      cs_removeFromCart(tempId);
    }
  },
  updateCartItemQty({ commit, state }, { type, product }) {
    const index = state.cartList.findIndex(
      (cartItem) => cartItem.id === product.id
    );

    if (index > -1) {
      if (type == 'INCREASE') commit('increaseCartItemQty', index);
      else if (type == 'DECREASE') commit('decreaseCartItemQty', index);
      else commit('updateCartItemQty', { product, index });
    }
  },
  clearCart({ commit }) {
    commit('clearCart');
    // clear temp cart
    cs_clearCart();
  },

  clearOrderedItem({ commit }, orderList = []) {
    orderList.forEach((order) => {
      order.orderDetailList.forEach((orderItem) => {
        const index = state.cartList.findIndex(
          (cartItem) => cartItem.id === orderItem.productId
        );

        if (index > -1) {
          const cartItem = state.cartList[index];

          if (cartItem) {
            commit('removeItemFromCart', index);
            // remove from temp
            if (cartItem.tempId) cs_removeFromCart(cartItem.tempId);
          }
        }
      });
    });
  },
};

const mutations = {
  addItemToCart(state, item) {
    if (item.qty && qtyRegx.test(item.qty)) state.cartList.push(item);
    else state.cartList.push({ ...item, qty: 1 });
  },
  removeItemFromCart(state, index) {
    state.cartList.splice(index, 1);
  },
  increaseCartItemQty(state, index) {
    if (qtyRegx.test(state.cartList[index].qty + 1))
      state.cartList[index].qty++;
  },
  decreaseCartItemQty(state, index) {
    if (state.cartList[index].qty > 1) state.cartList[index].qty--;
  },
  increaseCartItemQtyByQty(state, { index, qty }) {
    if (qtyRegx.test(state.cartList[index].qty + qty))
      state.cartList[index].qty += qty;
  },
  updateCartItemQty(state, { product, index }) {
    if (qtyRegx.test(product.qty)) state.cartList[index].qty = product.qty;
    else state.cartList[index].qty = 1;
  },
  clearCart(state) {
    state.cartList = [];
  },
  setCart(state, cartList) {
    state.cartList = cartList;
  },
};

export const cart = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
