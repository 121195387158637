<template>
  <b-nav-item-dropdown
    class="py-0 lang-switcher"
    :text="`${$t('topBar_LangSwitcher')} - ${$i18n.locale}`"
    right
  >
    <b-dropdown-item
      v-for="(lang, i) in langs"
      :key="`Lang${i}`"
      @click="setLocale(lang.value)"
    >
    <b-img v-if="lang.label==='Myanmar'" width="20" height="20" rounded="circle" src="../assets/images/Flag_of_Myanmar.svg.png"></b-img>
    <b-img v-if="lang.label==='English'" width="20" height="20" rounded="circle" src="../assets/images/Flag_of_the_United_Kingdom.svg.png"></b-img>
      {{ lang.label }} 
      </b-dropdown-item
    >
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
export default {
  components: { BNavItemDropdown, BDropdownItem,BImg },
  data: () => ({
    langs: [
      { label: "English", value: "en" },
      { label: "Myanmar", value: "my" },
    ],
  }),
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style lang="scss">
.nav {
  .lang-switcher {
    .nav-link {
      font-size: 14px;
      color: #737373;
      font-weight: lighter;
      text-transform: uppercase;
    }
  }
}
</style>
