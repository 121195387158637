import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import { cart } from './cart.module';
import { checkout } from './checkout.module';
import { recentlyViewedItems } from './recentlyViewedItems.module';
import shopService from '../services/shop.service';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentShop: null,
    currentError: null,
    settings: [],
    ads: [],
  },
  getters: {
    currentShop: (state) => state.currentShop,
    getSetting: (state) => (name) => {
      let setting = state.settings.filter((setting) => setting.name === name);
      if (setting.length > 0) return setting[0];
      else return {};
    },
    getCurrentError: (state) => state.currentError,
    getAds: (state) => (position, placement, sitePage) =>
      state.ads.find(
        (ads) =>
          ads.position === position &&
          ads.placement === placement &&
          ads.sitePage === sitePage
      ),
  },
  actions: {
    getCurrentShop({ commit }, shopId) {
      if (/^\d+$/.test(shopId)) {
        shopService.getShopById(shopId).then(
          (response) => {
            commit('enterShop', response.data);
          },
          (error) => {
            console.error(error);
            commit('setError', error.response.data);
          }
        );
      } else {
        shopService.getShopBySlug(shopId).then(
          (response) => {
            commit('enterShop', response.data);
          },
          (error) => {
            console.error(error);
            commit('setError', error.response.data);
          }
        );
      }
    },
    removeCurrentShop({ commit }) {
      commit('clearShop');
    },
    saveSettings({ commit }, payload) {
      payload.forEach((setting) => {
        if (
          (setting.name == 'HOMEPAGEADS' || setting.name == 'CAROUSEL') &&
          setting.value
        ) {
          let image = String(setting.value).split('|');
          // parse obj
          setting.objArr = image.map((strObj) => {
            return JSON.parse(strObj);
          });
        }
      });
      commit('setSettings', payload);
    },
    saveAds({ commit }, payload) {
      commit('setAds', payload);
    },
  },
  mutations: {
    enterShop(state, shop) {
      state.currentShop = shop;
    },
    clearShop(state) {
      state.currentShop = null;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setError(state, payload) {
      state.currentError = payload;
    },
    setAds(state, payload) {
      state.ads = payload;
    },
  },
  modules: {
    auth,
    recentlyViewedItems,
    cart,
    checkout,
  },
});
