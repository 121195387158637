import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

var firebaseConfig = {
  apiKey: 'AIzaSyDukNiPBXKrJtG0qAEd_hS5G1YSftvB1dQ',
  authDomain: 'judo-e-commerce-moi-emart.firebaseapp.com',
  projectId: 'judo-e-commerce-moi-emart',
  storageBucket: 'judo-e-commerce-moi-emart.appspot.com',
  messagingSenderId: '778359864424',
  appId: '1:778359864424:web:c98d4242f16b023879cfdd',
};

let firebase = initializeApp(firebaseConfig);

export default getMessaging(firebase);
