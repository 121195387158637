import axios from "../helpers/axios-auth"

class PromoService{
    getPromo(){
        return axios.get("/get-banner-weekly-promotions" )
    }
    getPromoById(promoId){
        return axios.get("/weekly-promotion/" + promoId)
    }
}
export default new PromoService()