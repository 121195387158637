<template>
  <b-nav>
    <!-- <b-nav-item :to="`/cart`">
      <p class="h5 m-0">
        <b-icon-cart-3></b-icon-cart-3>
        <b-badge v-if="itemCount" pill variant="light">{{ itemCount }}</b-badge>
      </p>
    </b-nav-item> -->
    <b-nav-item :to="`/cart-by-shop`">
      <p class="h5 m-0">
        <b-icon-cart-3></b-icon-cart-3>
        <b-badge v-if="itemCount" pill variant="light">{{ itemCount }}</b-badge>
      </p>
    </b-nav-item>

    <b-nav-item class="d-none d-md-none d-lg-block" to="/user/favorites">
      <p class="h5 m-0" style="padding-top: 2px">
        <b-icon-heart></b-icon-heart>
      </p>
    </b-nav-item>
    <b-nav-item>
      <p class="h5 m-0" style="padding-top: 2px"><qr-dialog></qr-dialog></p>
    </b-nav-item>

    <notification-dropdown></notification-dropdown>

    <b-nav-item v-if="!loggedIn" class="account" to="/login">
      {{ $t('account_login') }}
    </b-nav-item>

    <template v-if="loggedIn">
      <b-nav-item-dropdown :text="user.username" right class="mt-1">
        <b-dropdown-item to="/user/profile" v-b-toggle.navbar-toggle-collapse>
          {{ $t('account_profile') }}
        </b-dropdown-item>
        <b-dropdown-item
          class="d-block d-lg-none"
          to="/user/favorites"
          v-b-toggle.navbar-toggle-collapse
        >
          {{ $t('common_favList') }}
        </b-dropdown-item>
        <b-dropdown-item
          to="/user/order-history"
          v-b-toggle.navbar-toggle-collapse
        >
          {{ $tc('common_order', 2) }}
        </b-dropdown-item>
        <b-dropdown-item to="/order-tracking" v-b-toggle.navbar-toggle-collapse>
          {{ $t('common_orderTracking') }}
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          @click="$store.dispatch('auth/logout')"
          v-b-toggle.navbar-toggle-collapse
        >
          {{ $t('account_logout') }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </template>
  </b-nav>
</template>
<script>
import {
  BNav,
  BNavItem,
  BIconCart3,
  BIconHeart,
  VBToggle,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
} from 'bootstrap-vue';
import QrDialog from '../QrDialog.vue';

export default {
  components: {
    BNav,
    BNavItem,
    BIconCart3,
    BIconHeart,
    QrDialog,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    NotificationDropdown: () =>
      import('../notification/NotificationDropdown.vue'),
  },
  data: () => ({}),
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    itemCount() {
      return this.$store.getters['cart/itemCount'];
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
