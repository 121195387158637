<template>
  <div>
    <b-row class="shadow-sm" no-gutters>
      <b-col cols="12" class="bg-gradient-primary p-3 pt-1">
        <div class="container">
          <h2 class="text-left text-white mt-2 text-uppercase">
            {{ $t('account_login') }}
          </h2>
        </div>
      </b-col>
    </b-row>
    <b-container>
      <b-row align-h="center" class="pt-5">
        <div>
          <b-card no-body class="overflow-hidden" style="max-width: 1000px">
            <b-row no-gutters>
              <b-col cols="12" md="7">
                <div>
                  <b-card-img-lazy
                    blank-color="#f1f1f1"
                    fluid-grow
                    img-left
                    :src="require('../assets/images/cover-login.png') || ''"
                    alt="Image"
                    class="rounded-0"
                    style="min-width: 300px; height: auto"
                  ></b-card-img-lazy>
                </div>
              </b-col>
              <b-col cols="12" md="5">
                <b-card-body :title="$t('account_loginWelcomeLabel')">
                  <b-form @submit.stop.prevent="onSubmit">
                    <p class="text-left text-danger">{{ responseMessage }}</p>
                    <!-- --- input --- -->
                    <b-form-group
                      id="login-username-group"
                      label-for="login-username"
                    >
                      <b-form-input
                        id="login-username"
                        v-model="$v.form.username.$model"
                        :state="validateState('username')"
                        :placeholder="$t('account_username')"
                        aria-describedby="login-username-live-feedback"
                        class="mt-5"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="login-username-live-feedback"
                      >
                        <span class="error" v-if="!$v.form.username.required">
                          Must not be empty.
                        </span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      id="login-password-group"
                      label-for="login-password"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        type="password"
                        :placeholder="$t('account_password')"
                        aria-describedby="login-password-live-feedback"
                        class="mt-5"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="login-password-live-feedback"
                      >
                        <span class="error" v-if="!$v.form.password.minLength">
                          Must have at least
                          {{ $v.form.password.$params.minLength.min }}
                          letters.
                        </span>
                        <span
                          class="error"
                          v-else-if="!$v.form.password.required"
                        >
                          Must not be empty.
                        </span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- --- end of input --- -->

                    <b-button
                      block
                      variant="primary"
                      class="mt-5"
                      type="submit"
                      :disabled="loading"
                    >
                      {{ $t('account_login') }}
                    </b-button>
                    <b-row class="pt-3">
                      <!-- <b-col md="5">
                        <p class="text-primary d-none">
                          လျှို့ဝှက်နံပါတ် မေ့နေပါသလား?
                        </p>
                      </b-col> -->
                      <b-col class="text-center">
                        <p class="text-indigo">
                          <router-link to="/register">
                            <u>{{ $t('account_registerHere') }}</u>
                          </router-link>
                        </p>
                      </b-col>
                      <b-col class="text-center">
                        <p class="text-indigo">
                          <router-link to="/register?type=phone">
                            <u>{{ $t('account_registerHereWithPhone') }}</u>
                          </router-link>
                        </p>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import {
  BCard,
  BCardImgLazy,
  BCardBody,
  BForm,
  BFormInput,
  BButton,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
import { deleteToken } from '@firebase/messaging';
import messaging from '../firebase';

export default {
  components: {
    BCard,
    BCardImgLazy,
    BCardBody,
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    BFormInvalidFeedback,
  },
  mixins: [validationMixin],
  data: () => ({
    loading: false,
    responseMessage: '',
    loggedInUser: {},
    form: {
      username: null,
      password: null,
    },
  }),
  metaInfo() {
    return {
      title: 'Login',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Please login to buy and online shopping',
        },
      ],
    };
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) return;
        else {
          this.loading = true;
          this.loggedInUser = await this.$store.dispatch(
            'auth/login',
            this.form
          );

          if (Notification.permission == 'granted') {
            await deleteToken(messaging);
          }

          let role = this.loggedInUser.role.roleName;

          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            if (role != 'BUYER') {
              window.location = process.env.VUE_APP_PUBLIC_PATH + 'backoffice/';
            } else {
              this.$store.dispatch('cart/getCartList'); // retrieve temp cart item after login - buyer
              this.$router.push('/');
            }
          }
          this.loading = false;
        }
      } catch (error) {
        this.responseMessage = error.response.data.error
          ? error.response.data.message
          : '';
        this.loading = false;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>
<style scoped>
.Login {
  color: blue;
}
</style>
