<template>
  <div v-if="promotionList && promotionList.id">
    <div class="promotion-image-container" v-if="!hidePromotionImage">
      <router-link :to="'/weekly-promotion/' + promotionList.id">
        <div class="image-wrapper">
          <div class="image-background"></div>
          <b-img style="opacity: 0.8" fluid :src="computedImageUrl"></b-img>

          <!-- <div
            style="font-weight: bold"
            class="text-overlay px-md-4 px-1 mt-md-2 d-flex justify-content-between align-items-center"
          >
            <div style="margin-bottom: 8px">
              {{ promotionList.bannerName }}
            </div>
            <div style="font-size: 10px; margin-bottom: 8px">
              <div>{{ startDate }}</div>
              <div>{{ endDate }}</div>
            </div>
          </div> -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PromoService from '../../services/promo.service';
import { BImg } from 'bootstrap-vue';
export default {
  components: { BImg },
  data: () => ({
    promotionList: [],
  }),
  mounted() {
    this.getPromotions();
  },
  computed: {
    hidePromotionImage() {
      return this.$route.path === `/weekly-promotion/${this.promotionList.id}`;
    },
    computedImageUrl() {
      return this.promotionList.homePageImageUrl;
    },
    startDate() {
      const date = new Date(this.promotionList.startDateInMilliSeconds);
      return date.toLocaleString();
    },
    endDate() {
      const date = new Date(this.promotionList.endDateInMilliSeconds);
      return date.toLocaleString();
    },
  },
  methods: {
    getPromotions() {
      PromoService.getPromo()
        .then((res) => {
          if(res.data.length>0){
            this.promotionList = res.data[0];
          }else {
            this.promotionList = null;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style scoped>
.promotion-image-container {
  width: 100%;
  position: relative;
}

.image-wrapper {
  position: relative;
  width: 100%;
}

.image-wrapper img {
  width: 100%;
  height: 50px;
}

/* .text-overlay {
  position: absolute;
  color: white;
  text-align: center;
  width: 100%;
  height: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  background-color: transparent;
} */
.image-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 5);
}
</style>
